.bigimg[data-v-046e5808] {
  margin-left: 70px;
}
.bigimg button[data-v-046e5808] {
  transform: translateY(-10px);
  margin-left: 20px;
}
.bigimg input[data-v-046e5808] {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
}