a[data-v-dbafee3c] {
  text-decoration: none;
  outline: none;
}
.main[data-v-dbafee3c] {
  width: 99%;
  margin: 0 auto;
}
.top[data-v-dbafee3c] {
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid #c5ccdc;
  border-width: 0 0 1px 1px;
  color: rgb(102, 102, 102);
}
.top_left[data-v-dbafee3c] {
  padding-left: 38px;
}
.main_content[data-v-dbafee3c] {
  padding: 28px 0 0 37px;
  min-height: 900px;
  max-height: 4000px;
  background-color: #f6f8f9;
}
.title[data-v-dbafee3c] {
  height: 50px;
  position: relative;
}
.title p[data-v-dbafee3c] {
  width: 100%;
  position: absolute;
  left: -36px;
  border-bottom: 1px solid #c5ccdc;
  padding-left: 32px;
}
.title p a[data-v-dbafee3c] {
  color: #02a6ee;
}
.tab_content[data-v-dbafee3c] {
  padding: 10px 0 0 10px;
}
.tab_size[data-v-dbafee3c] {
  width: 95%;
}
[data-v-dbafee3c] .el-tabs__item {
  padding: 0 46px;
  background-color: #ddffff !important;
  font-size: 17px;
  color: rgb(102, 102, 102);
  border: none;
  width: 14.3%;
}
[data-v-dbafee3c] .el-tabs__nav {
  border: none !important;
  width: 100%;
}
[data-v-dbafee3c] .el-tabs__header {
  margin: 0 !important;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active[data-v-dbafee3c] {
  background-color: #3399ff !important;
  border-right: 1px solid gray;
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  color: #fff;
}
.el-tabs--card > .el-tabs__header .el-tabs__item[data-v-dbafee3c] {
  width: 14.3%;
}
.tab_each[data-v-dbafee3c] {
  border: 1px solid #d7d7d7;
  border-top: none;
  height: 2000px;
}
.tab_list[data-v-dbafee3c] {
  width: 99%;
  margin: 0 auto;
  padding-top: 19px;
}
.el-row[data-v-dbafee3c] {
  padding: 7px 0;
  border-bottom: 1px dashed #cdcbca;
  align-items: center;
}
.grid-content[data-v-dbafee3c] {
  text-align: right;
  padding-right: 30px;
  font-size: 16px;
  line-height: 40px;
  color: rgb(102, 102, 102);
}
.grid-hint[data-v-dbafee3c] {
  color: rgb(102, 102, 102);
  font-size: 14px;
}
.grid-button[data-v-dbafee3c] {
  text-align: center;
}
.grid-button button[data-v-dbafee3c] {
  background-image: linear-gradient(#00bafa, #0391dd);
  width: 124px;
  height: 42px;
  border: none;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
}
.upload[data-v-dbafee3c] {
  width: 70px;
  height: 30px;
  color: rgb(102, 102, 102);
  font-size: 14px;
  padding: 0;
}
[data-v-dbafee3c] .el-radio__label {
  font-size: 16px;
}
[data-v-dbafee3c] .el-checkbox__label {
  font-size: 16px;
}
.el-form-item[data-v-dbafee3c] {
  margin-bottom: 0;
}